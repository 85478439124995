<template lang="pug">
.d-flex.justify-space-between.pt-3.mt-3.px-1.align-center.sticky.bottom-0.pb-3
  .d-flex.align-center
    .inline-block.text-xs.mr-2 Notifications per page
    v-select.text-xs(
      style="max-width: 80px"
      v-model="limit"
      :items="limitOptions"
      dense
      hide-details
      outlined
      @change="updatePagination"
    )

  .d-flex.align-center.gap-2( v-if="pagination" )
    span.inline-block.text-xs.grey--text
      | {{ context }}

    v-btn(
      small
      depressed
      outlined
      :disabled="page === 1"
      color="grey darken-1"
      @click="page = page - 1; updatePagination()"
    )
      v-icon mdi-menu-left

    v-btn(
      small
      depressed
      outlined
      :disabled="(page * limit) > pagination.total"
      color="grey darken-1"
      @click="page = page + 1; updatePagination()"
    )
      v-icon mdi-menu-right
  </template>
  
  <script>
  import { computed, reactive, toRefs } from '@vue/composition-api'
  
  export default {
    name: 'ListPagination',
  
    props: {
      pagination: {
        type: Object,
        required: true
      }
    },
  
    setup (props, { emit }) {
      const state = reactive({
        page: 1,
        limit: 50,
        skip: 0,
        limitOptions: [
          { value: 25, text: 25 },
          { value: 50, text: 50 },
          { value: 9999, text: 'All' }
        ]
      })
  
      const context = computed(() => {
        const pagination = props.pagination
        const total = pagination.total
        const { page, limit } = state
        const start = ((limit > total) || page === 1)
          ? 1 : ((page - 1) * limit)
        const end = (page * limit) > total ? total : (page * limit)
  
        return `${start}-${end} of ${total}`
      })
  
      const updatePagination = () => {
        emit('update', state)
      }
  
      return {
        context,
  
        ...toRefs(state),
        updatePagination
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
::v-deep .v-input.text-xs.v-text-field {
  input { padding: 2px 0px 4px; }
  .v-select__selections { padding: 1px }
  .v-select.v-input--dense .v-select__selection--comma {
    margin: 0px
  }
  .v-input__slot {
    min-height: 20px;
  }
  .v-input__append-inner {
    margin-top: 3px;
    padding: 0;
  }
}

td:nth-child(1) { width: 100%; }
</style>
