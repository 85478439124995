<template lang="pug">
.notifications-list.mt-6( v-if="!isPending" )
  div( style="max-width: 1000px" class="w-10/12 xl:w-1/2 mx-auto" )
    .d-flex.mb-8.items-center.justify-between
      h3 Notifications

    .d-flex.justify-space-between.mb-8
      v-btn-toggle(
        v-model="notificationStatus"
        small
        rounded
        color="primary"
        mandatory
      )
        v-btn.w-32( small ) New
        v-btn.w-32( small ) Dismissed

    ListPagination(
      :pagination="pagination"
      @update="updatePagination"
    )

    .space-y-4.mt-8
      div( v-if="isPending" )
        .h-24.animate-pulse.rounded.bg-gray-200
      div( 
        class="border rounded-lg cursor-pointer" 
        v-for="view of notificationViews"
        :key="view.id"
        v-if="!isPending"
      )
        NotificationCard( :item="view" @refresh="refresh" )

      .d-flex.flex-column.rounded.text-center.align-middle.w-full.justify-center.mt-16( 
        v-if="notificationViews.length === 0" 
      )
        v-icon.mb-2( x-large ) mdi-bell-outline
        h3.mt-2.text-lg.font-medium.text-gray-900 No notifications

</template>

<script>
import NotificationCard from './Notification.Card.vue';
import ListPagination from '@/components/ListPagination'
import useApiFind from '@/api/useApiFind';
import { reactive, ref, computed, onUnmounted } from '@vue/composition-api';

export default {
  name: 'NotificationsList',

  components: {
    NotificationCard,
    ListPagination
  },

  setup(props, { root: { $FeathersVuex, $store } }) {
    const { NotificationView } = $FeathersVuex.api;
    const notificationStatus = ref('')
    const state = reactive({
      page: 1,
      limit: 50,
      skip: 0
    })

    const params = computed(() => {
      const statusIdx = notificationStatus.value
      let statuses = ['new', 'viewed', 'selected', 'dismissed']
      
      switch (statusIdx) {
        case 0: 
          statuses = ['new', 'viewed', 'selected']; 
          break;
        case 1: 
          statuses = ['dismissed'];
          break;
        default: break;
      }

      return {
        query: {
          status: {
            $in: statuses
          },
          $limit: state.limit,
          $skip: state.skip,
          $eager: '[notification]',
          $sort: { createdAt: -1 }
        }
      }
    });
    const { 
      items: notificationViews, 
      isPending, 
      findItems: findNotifications,
      pagination
    } = useApiFind({
      model: NotificationView,
      params
    });

    const refresh = () => {
      findNotifications(params)
    }

    onUnmounted(() => {
      if (notificationViews.value.some(n => n.status === 'new')) {
        $store.dispatch('notification-views/patch', 
          [null, { status: 'viewed' }, { query: { 'status': 'new' } }]);
      }
    })

    const updatePagination = ({ limit, page, skip }) =>
      Object.assign(state, { limit, page, skip })

    return { 
      notificationViews,
      isPending,
      pagination,

      notificationStatus,

      refresh,
      updatePagination
    }
  }
}
</script>